import { render, staticRenderFns } from "./SwitchDB.vue?vue&type=template&id=5e91a016&scoped=true"
import script from "./SwitchDB.vue?vue&type=script&lang=ts"
export * from "./SwitchDB.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e91a016",
  null
  
)

export default component.exports