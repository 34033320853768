<template>
  <section id="content">
    <PageTitle title="Database"></PageTitle>
    <div class="wrapper wrapper-content animated fadeInRight">

      <div class="row">
        <div class="col-lg-12">
          <List></List>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <Copy></Copy>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <SwitchDB></SwitchDB>
        </div>
      </div>

    </div>

  </section>
</template>

<style scoped>
  
</style>



<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import * as api from '@fwk-client/utils/api';
import { messagesTypes, MessageTypes } from '@fwk-client/store/types';
import PageTitle from '../../panels/PageTitle.vue';
import List from '../../panels/admin/database/List.vue';
import Copy from '../../panels/admin/database/Copy.vue';
import SwitchDB from '../../panels/admin/database/SwitchDB.vue';

@Component({
  components: { 
    PageTitle,
    List,
    Copy,
    SwitchDB
  }
})
export default class Database extends mixins(GenericPage) {
  
}
</script>