<template>
  
  <div class="ibox">
    <div class="ibox-title">
      <h2>Test connection</h2>
    </div>
    <div :class="'ibox-content p-md' + (connectionLoading ? ' sk-loading' : '')">

      <div v-if="connectionLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <b-table 
          outlined striped
          :items="configsProvider"
          :fields="configFields"
          ref="listConfigs">
        <template v-slot:cell(options)="row">
          <a href="javascript:void(0)" @click="dump(row.item)">Dump</a>
          /
          <a href="javascript:void(0)" @click="restore(row.item)">Restore</a>
        </template>
      </b-table>

      <input type="file" ref="file" @change="onRestoreFileChange" style="display:none;"/>

    </div>
  </div>
        
</template>

<style scoped>
  
</style>



<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import * as api from '@fwk-client/utils/api';
import { messagesTypes, MessageTypes } from '@fwk-client/store/types';

@Component({
  components: {}
})
export default class List extends Vue {

  connectionLoading = false;

  form = {
    file : null
  }

  configToRestore:any = null;

  result = "";

  configFields = [
      {
        key: "title"
      },
      {
        key: "options"
      }
    ];

  configsProvider(ctx:any) {
    var options:api.ApiVueOptions =  {
      app: this
    }

    return api.getAPI('/api/admin/database/configs', options).then((response:any) => {
      if(response.configs) {  
        return response.configs;
      }
      else {
        return [];
      }
    })
  }

  dump(config:any) {
    var options:api.ApiVueOptions =  {
      app: this
    }

    this.connectionLoading = true;

    return api.getAPI('/api/admin/database/dump/'+config.title, options).then((response:any) => {
      this.connectionLoading = false;
    })
  }

  restore(config:any) {

    // We update the config to restore
    this.configToRestore = {...config};
    
    // We trigger the click on the file input.
    // @ts-ignore
    this.$refs.file.click();
  }

  onRestoreFileChange() {
    // @ts-ignore
    var file = this.$refs.file.files[0];
    
    if(!this.configToRestore) {
      this.$store.commit('messages/' + messagesTypes.mutations.ADD_GENERIC_MESSAGE, {
                message: 'There is no config selected to be restored',
                type: MessageTypes.ERROR
            });
      return;
    }

    var formData = new FormData();
    formData.append("file", file, file.name);  

    var options:api.ApiVueOptions =  {
      app: this
    }
    
    this.connectionLoading = true;

    api.postAPIFormData('/api/admin/database/restore/'+this.configToRestore.title, formData, options).then((response:any) => {
      this.configToRestore = null;
      // @ts-ignore
      this.$refs.file.value = "";
      this.connectionLoading = false;
    });
  }
  
}
</script>