<template>
  
  <div class="ibox">
    <div class="ibox-title">
      <h2>DB Switch</h2>
    </div>
    <div :class="'ibox-content p-md' + (switchLoading ? ' sk-loading' : '')">

      <div v-if="switchLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <form role="form" @submit="switchDB" name="switchDBForm">
        <div class="form-group row">
          <div class="col">
            Current connection: {{current}}
          </div>
        </div>
        <div class="form-group row">
          <div class="col">
            <v-select :options="listDBs" :getOptionLabel="listDBsOptionLabel" v-model="form.target" placeholder="Select a target DB" @keypress.enter.native.prevent="">
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="!form.target"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
          </div>
        </div>
        <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">Switch</button>
      </form>

    </div>
  </div>
        
</template>

<style scoped>
  
</style>



<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import * as api from '@fwk-client/utils/api';
import { messagesTypes, MessageTypes } from '@fwk-client/store/types';
import * as Ladda from 'ladda';

@Component({
  components: {}
})
export default class SwitchDB extends Vue {

  switchLoading = false

  listDBs:any[] = [];
  current = "";

  form:any = {
    target : null,
  }

  laddaSubmit:Ladda.LaddaButton|null = null;

  created() {
    this.updateListDBs();
  }

  mounted() {
    var button:HTMLButtonElement|null = document.querySelector( 'form[name=switchDBForm] button' );
    this.laddaSubmit = Ladda.create(button!);
  }

  updateListDBs() {
    // We need to get the list of available companies for the current logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }
    api.getAPI('/api/admin/database/configs', options).then((response:any) => {
      if(response.configs) {  
        this.listDBs = response.configs;
        this.current = response.current;
      }
      else {
        this.listDBs = [];
      }
    });
  }

  listDBsOptionLabel(option:any) {
    return option.title;
  }

  switchDB(evt:Event) {
    evt.preventDefault();

    var options:api.ApiVueOptions =  {
      app: this
    }

    var input = {
      target : this.form.target.title
    }

    this.switchLoading = true;

    return api.postAPI('/api/admin/database/switch',input, options).then((response:any) => {
      if(response.result == "ok") {
        // We logout the user
        window.location.href = '/api/auth/logout';
      }
      this.switchLoading = false;
    })
  }
  
}
</script>